<template>
  <v-card elevation="0" flat v-if="taskItem && items.length > 0">
    <v-card-title>조치 사진</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="3" v-for="proof in items" :key="proof._id">
          <proof :proofItem="proof" :lazy-src="lazySrc" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import api from '@/services/api'
export default {
  name: 'DispatchProofs.vue',
  props: {
    taskItem: { type: Object, default: () => {} },
  },
  data() {
    return {
      lazySrc: require('@/assets/images/arctic-logo.svg'),
      items: []
    }
  },
  methods: {
    list() {
      if (this.taskItem._id) {
        api.getTaskProofsByTaskId(this.taskItem._id).then(r => {
          this.items = r.result
        })
      }
    },
  },
  watch: {
    taskItem() {
      this.list()
    }
  },
  components: {
    Proof: () => import('@/views/components/dispatch/Proof')
  }
}
</script>
